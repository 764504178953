var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mt-16 pa-2",attrs:{"elevation":"0","color":"#FAFAFA","width":"300","height":"100"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-text-field',{attrs:{"autofocus":"","flat":"","solo":"","placeholder":"请输入里程碑名称","dense":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.saveMilestone.apply(null, arguments)}},model:{value:(_vm.newMilestone.ms_name),callback:function ($$v) {_vm.$set(_vm.newMilestone, "ms_name", $$v)},expression:"newMilestone.ms_name"}})],1),_c('v-row',{staticClass:"ma-0 align-center"},[_c('v-icon',[_vm._v("mdi-account-outline")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"tag":"div","width":"50","text":"","small":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm._f("username")(_vm.newMilestone.ms_owner)))])]}}])},[_c('UserPicker',{on:{"pick":_vm.changeMilestoneOwner}})],1),_c('date-range-picker',{ref:"picker",attrs:{"opens":"left","control-container-class":"text-caption align-center d-flex","locale-data":{ 
            firstDay: 1,
            format: 'yyyy-mm-dd',
            applyLabel: '确定',
            cancelLabel: '取消',
            daysOfWeek: ['日', '一', '二', '三', '四', '五', '六'],
            monthNames: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
        },"minDate":"2018-01-01","maxDate":"2050-12-31","dateRange":{startDate: _vm.newMilestone.ms_start_date, endDate: _vm.newMilestone.ms_end_date},"ranges":false},on:{"update":_vm.changeMilestoneDate},scopedSlots:_vm._u([{key:"input",fn:function(picker){return [_c('div',{staticStyle:{"cursor":"pointer"}},[_c('v-icon',[_vm._v("mdi-calendar-month-outline")]),_c('span',[_vm._v("时间")])],1)]}}])}),_c('v-btn',{staticClass:"mx-1",attrs:{"elevation":"0","x-small":""},on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('v-btn',{staticClass:"mx-1",attrs:{"disabled":!_vm.newMilestone.ms_name,"elevation":"0","x-small":"","color":"grey"},on:{"click":_vm.saveMilestone}},[_vm._v("保存")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }